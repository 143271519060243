import { CONFIG_SCHEMA } from './config';
import { fetchGraphQL } from './fetchApi';

const operationsDoc = `
  query GetApplicationAccess($role_id:[Int!]){
    ${CONFIG_SCHEMA}_mbd_access_control(where: {role_id: {_in:$role_id},access_level: {_eq: "screen"},access_type:{_eq:"S"}}) {
    screen_grid_id
      access_level,
      access_type
      mbd_screen_to_datagrid{
          mbd_screen{
            screen_id
            screen_internal_name
            screen_en_display_name
            screen_de_display_name
            app_en_display_name
            app_de_display_name
          }
            }
      }
  }
  `;

function fetchGetAccessControlDetails(roleId) {
  return fetchGraphQL(operationsDoc, 'GetApplicationAccess', { role_id: roleId });
}

async function startFetchGetAccessControlDetails(roleId) {
  const { errors, data } = await fetchGetAccessControlDetails(roleId);

  if (errors) {
    // handle those errors like a pro
    console.error(errors);
  }

  // do something great with this precious data
  return data;
}

export const getAccessControlDetails = (roleId) => startFetchGetAccessControlDetails(roleId);
