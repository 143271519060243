import * as React from 'react';
import '../styles/globals.css';
import { useState, useEffect } from 'react';
import LanguageContextProvider from '../shared/context/LanguageContext';
import UserContextProvider from '../shared/context/UserContext';
import { ThemeProvider } from '@airbus/components-react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ConfigContextProvider from '../shared/context/ConfigContext';

function MyApp(props) {
  const { Component, pageProps } = props;
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  return (
    <ConfigContextProvider>
      <LanguageContextProvider>
        <UserContextProvider>
          {isClient ? (
            <ThemeProvider brand='airbus'>
              <Component {...pageProps} />
            </ThemeProvider>
          ) : (
            ''
          )}
        </UserContextProvider>
      </LanguageContextProvider>
    </ConfigContextProvider>
  );
}

export default MyApp;
