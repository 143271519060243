import { JSONValue } from '../../types';
import config from './config.json';

export const CONFIG_SCHEMA = process.env.NEXT_PUBLIC_MBD_CONFIG_SCHEMA;
export const screenConfig: JSONValue = config.screen_config || '{}';

export const COLUMN_CONFIG_NAME = `
{
    id
	screen_grid_id
    mbd_screen_to_datagrid{
	 mbd_screen{
		screen_id
		screen_internal_name
		screen_en_display_name
		screen_de_display_name
		app_en_display_name
		app_de_display_name
     }
    }
    mbd_table_column_group{
      col_group_id
      col_de_group_name
      col_en_group_name
    }
    col_group_id
    grid_column
    form_order_no
    col_internal_name
    col_en_display_name
    col_de_display_name
    col_data_type
    col_duplicate_config
    col_max_value
    col_min_value
    col_min_width
    mandatory_column
    mutation_column_name
    mutation_table_name
    mutation_table_pkey
    access_configurable
}
`;
export const viewName = `
{
  sno
  screen_id
  user_id
  mbd_application {
    screen_id
    app_name
    description
  }
  view_name
  view_id
  view_columns
  default_view
  cust_column_order
  col_features
  active
}
`;
export const APP_CONFIG_NAME = `
{
  sno
  screen_id
  mbd_application {
    screen_id
    app_name
  }
   page_per_rows
  singledataset
  singledatasetgroup
  addrow
  clonerow
  dataexport
  deleterow
  filtering
  inlinesearch
  internationalization
  panel_cbtn
  quicksearch
  editrow
  reload
  savedata
  sorting
  viewmanagement
  updaterow
}
`;
export const TableDeatils = `
  {
      id
      mbd_data_grid {
        data_grid_id
        data_grid_internal_name
        table_name
        schema_name
        pkey_column
        form_data_source
        sorting
        quicksearch
        inlinesearch
        filtering
        dataexport
        page_per_rows
        viewmanagement
        singledatasetgroup
        panel_cbtn
        no_of_rows_per_page
      }
    }
  `;
export const queryName = 'npi_sheet2';

export const colOperationsDoc = (screenGridId) => `
    query MyQuery {
      ${CONFIG_SCHEMA}_mbd_table_column_config(where: {screen_grid_id: {_eq: ${screenGridId}}}) ${COLUMN_CONFIG_NAME}
    }
  `;
export const DataTableViews = (screenGridId, roleId, reportName) =>
  `query MyQuery($role_id: Int, $screen_grid_id: Int, $report_name: String) {
    mbdcm_mbd_role_to_report_mapping(where: {role_id: {_in: [${roleId}]}, screen_grid_id: {_eq: ${screenGridId}}, report_name: {_eq: "${reportName}"}}) {
      screen_grid_id
      mbd_screen_report_config {
        report_name
        report_en_name
        report_de_name
        schema_name
        view_name
        mbd_report_column_configs {
          col_internal_name
          col_de_display_name
          col_en_display_name
        }
      }
    }
  }
`;
