import React, { createContext, useState, useMemo, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import { getUserDetails } from '../services/getUserRole';
import { getAccessControlDetails } from '../services/getAccessControlService';
import { CONFIG_SCHEMA } from '../services/config';
import { UserData } from '../types';
//To show new view name that is added along with respective columns that is selected.

export const UserContext = createContext(null);

export const UserContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { push } = useRouter();

  const [userDetails, setUserDetails] = useState(null); //user_id: '', role_id: "", screen_id: ""
  const [loginDetails, setLoginDetails] = useState<UserData>({});
  const [accessControlDetails, setAccessControlDetails] = useState([]);
  const [subMenuDetails, setSubMenuDetails] = useState({});

  useEffect(() => {
    async function fetchSession() {
      const response = await fetch('/api/auth/session', {
        method: 'GET',
        headers: { Accept: 'application/json' },
      });
      if (response.ok) {
        const payload = await response.json();
        setLoginDetails(payload.data);
      } else {
        push('/');
      }
    }
    fetchSession();
  }, []);

  useEffect(() => {
    if (loginDetails?.userId) {
      getUserDetails(loginDetails.userId).then((userDetails) => {
        const userData = userDetails?.[`${CONFIG_SCHEMA}_mbd_user_role_mapping`];
        if (!userData?.length) {
          push('/');
          return;
        }
        const roleIds = userData.map(({ role_id: roleId }) => roleId);
        setUserDetails({ ...userData[0], role_id: roleIds });
        getAccessControlDetails(roleIds).then((accessControlData) => {
          const accessData = accessControlData[`${CONFIG_SCHEMA}_mbd_access_control`];
          if (!accessData.length) {
            push('/');
            return;
          }
          const access = new Map(accessData.map((item) => [item.screen_grid_id, item]));
          const uniqueAccess = [...access.values()];
          setAccessControlDetails(uniqueAccess);
        });
      });
    }
  }, [loginDetails]);

  const updateUserDetails = (obj) => {
    setUserDetails((prestate) => ({ ...prestate, ...obj }));
  };

  const updateSubMenuDetails = (subMenu) => {
    setSubMenuDetails((prestate) => ({ ...prestate, ...subMenu }));
  };

  const updateAccessControlDetails = (accessControlData) => {
    setAccessControlDetails(accessControlData);
  };

  const updateLoginDetails = (loginData) => {
    setLoginDetails((prestate) => ({ ...prestate, ...loginData }));
  };

  const value = useMemo(
    () => ({
      userDetails,
      updateUserDetails,
      accessControlDetails,
      updateAccessControlDetails,
      subMenuDetails,
      updateSubMenuDetails,
      loginDetails,
      updateLoginDetails,
    }),
    [
      updateUserDetails,
      userDetails,
      subMenuDetails,
      accessControlDetails,
      updateAccessControlDetails,
      updateSubMenuDetails,
      loginDetails,
      updateLoginDetails,
    ],
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
export default UserContextProvider;

export function useUserContextApi() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}
