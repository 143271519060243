import { useState, useEffect } from 'react';
import { Spinner, Inline } from '@airbus/components-react';
import styles from './Spinner.module.css';
import { t } from '../../i18next';

export default function Spinners() {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return !isClient ? null : (
    <Inline className={styles.flexSpinner}>
      <Spinner size='medium' label={t('please_wait')}></Spinner>
    </Inline>
  );
}
