export async function fetchGraphQL(operationsDoc, operationName, variables) {
  const result = await fetch('/api/fetchGraphql', {
    method: 'POST',
    body: JSON.stringify({
      query: operationsDoc,
      variables: variables,
      operationName: operationName,
    }),
  });
  return await result.json();
}
