import { fetchGraphQL } from './fetchApi';
import { CONFIG_SCHEMA } from './config';
const operationsDoc = `   
    query GetUserRoleDetails($user_id: String) {
      ${CONFIG_SCHEMA}_mbd_user_role_mapping(where: {user_id: {_eq: $user_id}}) {
        user_id
        role_id
        mbd_user{
          firstname
          lastname
        }
      }
    }
  `;

function fetchGetUserRoleDetails(userId) {
  return fetchGraphQL(operationsDoc, 'GetUserRoleDetails', { user_id: userId });
}

async function startFetchGetUserRoleDetails(userId) {
  const { errors, data } = await fetchGetUserRoleDetails(userId);

  if (errors) {
    // handle those errors like a pro
    console.error(errors);
  }

  // do something great with this precious data
  return data;
}

export const getUserDetails = (userId) => startFetchGetUserRoleDetails(userId);
