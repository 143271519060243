import React, { useContext, useMemo } from 'react';

import i18next from '../../i18next';
import { TFunction } from 'i18next';

type Lang = 'en' | 'de';
type LanguageContextType = {
  t: TFunction;
  language: Lang;
  changeLang: (lng: Lang) => void;
};

export const LanguageContext = React.createContext<LanguageContextType | null>(null);

const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [language, setLanguage] = React.useState<Lang>('en');
  const [t, setT] = React.useState<TFunction>(() => i18next.t);

  React.useEffect(() => {
    const browserLanguage = navigator.language === 'de' ? 'de' : 'en';
    i18next.changeLanguage(browserLanguage);

    function handleLanguageChanged() {
      // update t function
      setLanguage(i18next.language as Lang);
      setT(() => i18next.t);
    }

    // cb called when i18next.changeLanguage called
    i18next.on('languageChanged', handleLanguageChanged);
    return () => i18next.off('languageChanged', handleLanguageChanged);
  }, []);

  const changeLang = (lang: Lang) => {
    i18next.changeLanguage(lang);
  };

  const value = useMemo(() => ({ t, language, changeLang }), [t, language, i18next.language]);
  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export default LanguageProvider;
export function useLanguageContextAPI() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}
