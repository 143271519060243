import { createContext, useContext, useEffect, useState } from 'react';
import Spinners from '../components/Spinner';
import { ConfigResponseType } from '../types';

const ConfigContext = createContext<ConfigResponseType>({});

export default function ConfigContextProvider({ children }) {
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchConfig() {
      try {
        setLoading(true);
        const result = await fetch('/api/fetchConfig');
        const jsonResult = await result.json();
        setConfig(jsonResult);
        setLoading(false);
      } catch (e) {
        throw new Error('Exception while loading config');
      }
    }

    fetchConfig();
  }, []);

  if (loading) {
    return <Spinners />;
  }

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}

export function useConfigContext() {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('Config Context must be used within a Provider');
  }
  return context;
}
